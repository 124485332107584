<!--
 * @Description: 我的订单页面组件
 * @Author: hai-27
 * @Date: 2020-02-20 17:21:54
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-27 13:36:27
 -->
<template>
	<div class="uc-box uc-main-box">
		<ul class="steps clearfix flexcen">
			<li class="first prev ok"><b>1</b><span class="" :class="next ?'going':''"></span><em class="f">填写身份信息</em>
			</li>
			<li class=" " :class="next ?'first prev ok':'cur'"><b>2</b><span class=""></span><em class="f">填写店铺信息</em>
			</li>
			<!-- <li class="prev ok"><b>3</b><span class="going"></span><em class="f">填写身份信息</em></li> -->
			<!-- <li class="cur ok"><b>4</b><span class="going"></span><em class="f">结算账户</em></li> -->
			<li class="last"><b>3</b><em class="f">等待审核</em></li>
		</ul>
		<div class="uc-content-box order-list-box">
			<!-- 我的订单头部 -->
			<div class="box-hd">
				<h6 class="title">商家入驻信息</h6>
				<div class="clearfix"></div>
			</div>
			<!-- 我的订单头部END -->

			<!-- 我的订单主要内容 -->
			<div class="box-bd">
				<div class="userInfo">
					<!-- <div class="box">
						<div class="text">
							<span class="redxx">*</span>
							企业/个体：
						</div>
						<div class="rt">
							<input type="text" class="input" placeholder="企业/个体">
						</div>
					</div> -->
					<div v-if="!next">

						<div class="box" v-if="shopdata.isshop">
							<div class="text">
								<span class="redxx">*</span>
								注册号：
							</div>
							<div class="rt">
								{{dataValues.BaoFuloginNo}}
							</div>
						</div>
						<div class="box" v-if="shopdata.isshop">
							<div class="text">
								<span class="redxx">*</span>
								商户号：
							</div>
							<div class="rt">
								{{dataValues.BaoFuNo}}
							</div>
						</div>
						<div class="box" v-if="shopdata.isshop">
							<div class="text">
								<span class="redxx">*</span>
								待结算余额：
							</div>
							<div class="rt" style="color:red;">
								{{shopdata.settleamount}}
							</div>
						</div>
						<div class="box" v-if="shopdata.isshop">
							<div class="text">
								<span class="redxx">*</span>
								店铺余额：
							</div>
							<div class="rt">
								{{shopdata.amount}}
								<a href="javascript:[0];" @click="withdrawFun()"
									style="color:red; margin-left: 10px;">提现申请</a>
							</div>
						</div>
						<div class="box">
							<div class="text">
								<span class="redxx">*</span>
								店铺名称：
							</div>
							<div class="rt">
								<input type="text" v-model="dataValues.Name" class="input" placeholder="请输入店铺名称">
							</div>
						</div>
						<div class="box">
							<div class="text">
								<span class="redxx">*</span>
								联系人：
							</div>
							<div class="rt">
								<input type="text" v-model="dataValues.RealName" class="input" placeholder="请输入联系人">
							</div>
						</div>
						<div class="box">
							<div class="text">
								<span class="redxx">*</span>
								联系人电话：
							</div>
							<div class="rt">
								<input type="text" v-model="dataValues.Mobile" class="input" placeholder="请输入联系人电话"
									maxlength="11">
							</div>
						</div>
						<div class="box">
							<div class="text">
								<span class="redxx">*</span>
								身份证号码：
							</div>
							<div class="rt">
								<input type="text" v-model="dataValues.IdCard" class="input" placeholder="请输入身份证号码"
									maxlength="18">
							</div>
						</div>
						<div class="box">
							<div class="text">
								<span class="redxx">*</span>
								银行类型：
							</div>
							<div class="rt layui-form">
								<select id="bank_type" name="BankCode" lay-filter="selctOnchange" lay-search="">
									<option value="">请选择银行</option>
									<option :value="item.value" v-for="(item,index) in yibaobanklist" :key="index">
										{{item.label}}</option>
								</select>
							</div>
						</div>
						<div class="box">
							<div class="text">
								<span class="redxx">*</span>
								银行卡号：
							</div>
							<div class="rt">
								<input type="text" v-model="dataValues.BankCardNo" class="input" placeholder="请输入银行卡号">

							</div>
						</div>
						<div class="box layui-form layui-form-item" id="distpicker2" data-toggle="distpicker"
							data-autoselect="3">
							<div class="text">
								<span class="redxx">*</span>
								选择省市区：
							</div>
							<div class="rt">
								<div class="layui-input-block" style="margin-bottom: 6px;margin-left: 0px;">
									<select id="address_sheng" name="quiz1" lay-filter="brickType1"></select>
								</div>
								<div class="layui-input-block" style="margin-bottom: 6px;margin-left: 0px;">
									<select id="address_shi" name="quiz2" lay-filter="brickType2"></select>
								</div>
								<div class="layui-input-block" style="margin-bottom: 6px;margin-left: 0px;">
									<select id="address_qv" name="quiz3" lay-filter="brickType3"></select>
								</div>
							</div>
						</div>
						<div class="box">
							<div class="text">
								<span class="redxx">*</span>
								详细地址：
							</div>
							<div class="rt">
								<input type="text" v-model="dataValues.AddressInfo" class="input"
									placeholder="请输入店铺地址信息">
							</div>
						</div>
					</div>

					<div v-if="next">
						<div class="box" style="align-items: initial;">
							<div class="text">
								<span class="redxx">*</span>
								企业营业执照：
							</div>
							<div class="rt">
								<el-upload class="upload-demo" :action="uploadUrl" :headers="headers" :limit="1"
									:on-remove="function(file, fileList){return handleRemove(file,fileList,0) }"
									:file-list="BusinessImgList" list-type="picture"
									:on-success="function(response, file, fileList){ return handleSuccess(response, file, fileList,0)}">
									<el-button size="small" type="primary" v-if="!shopdata.isshop">点击上传</el-button>
									<div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
								</el-upload>
							</div>
						</div>
						<div class="box" style="align-items: initial;">
							<div class="text">
								<span class="redxx">*</span>
								身份证正面：
							</div>
							<div class="rt">
								<el-upload class="upload-demo" :action="uploadUrl" :headers="headers" :limit="1"
									:on-remove="function(file, fileList){return handleRemove(file,fileList,1) }"
									:file-list="IdImg1List" list-type="picture"
									:on-success="function(response, file, fileList){ return handleSuccess(response, file, fileList,1)}">
									<el-button size="small" type="primary" v-if="!shopdata.isshop">点击上传</el-button>
									<div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
								</el-upload>
							</div>
						</div>
						<div class="box" style="align-items: initial;">
							<div class="text">
								<span class="redxx">*</span>
								身份证反面：
							</div>
							<div class="rt">
								<el-upload class="upload-demo" :action="uploadUrl" :headers="headers" :limit="1"
									:on-remove="function(file, fileList){return handleRemove(file,fileList,2) }"
									:file-list="IdImg2List" list-type="picture"
									:on-success="function(response, file, fileList){ return handleSuccess(response, file, fileList,2)}">
									<el-button size="small" type="primary" v-if="!shopdata.isshop">点击上传</el-button>
									<div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
								</el-upload>
							</div>
						</div>
						<div class="box" style="align-items: initial;">
							<div class="text">
								<span class="redxx">*</span>
								商家入驻合同：
							</div>
							<div class="rt">
								<el-upload accept=".pdf" :action="uploadUrl" :file-list="contractList"
									:before-upload="(res)=>beforeStudtUpload(res,1)" :show-file-list="false"
									:headers="headers" :on-change="imgPreview" :on-success="StudtUploadSuccess"
									class="upload-demo">
									<el-button size="small" type="primary" v-if="!shopdata.isshop">点击上传</el-button>
									<div slot="tip" class="el-upload__tip">只能上传pdf文件，且不超过500kb</div>
								</el-upload>

								<div class="pdflist" v-for="(item,index) in contractList" :key="index">
									<a :href="dataValues.ContractImg"><img src="../../images/pdf.png" alt=""
											style="height: 40px;width:40px">
										<span>{{ResumefileName.fileName}}</span>
									</a>
								</div>

								<a :href="$target +'/商家入驻合同.docx'" style="color: #409EFF;">《商家入驻合同》<img
										src="../../images/xiazai.png" alt="" style="width:17px;height:17px"></a>
							</div>
						</div>
					</div>
					<div class="box" v-if="dataValues.AuditState==2">
						<div class="text">
							<span class="redxx">*</span>
							审核说明：
						</div>
						<div class="rt">
							{{dataValues.AuditIntro}}
						</div>
					</div>
					<div style="width: 100%;" class="flexcen">
						<button class="btn btn-small btn-primary Personalbtn flexcen" :disabled="IsChecked"
							v-if="!shopdata.isshop" @click="SubmitShopInfo">{{next?'立即申请':"提交"}}</button>
					</div>
				</div>
			</div>
			<!-- 我的订单主要内容END -->
		</div>
	</div>
</template>
<script>
	import {
		distpicker
	} from '@/js/distpicker.js'
	import {
		getToken
	} from '@/utils/auth'
	export default {
		name: 'Order',
		data() {
			return {
				yibaobanklist:[{
						"label": "中国建设银行",
						"value": "CCB"
					},
					{
						"label": "中国工商银行",
						"value": "ICBC"
					},
					{
						"label": "中国银行",
						"value": "BOC"
					},
					{
						"label": "中国农业银行",
						"value": "ABC"
					},
					{
						"label": "中国邮政储蓄银行",
						"value": "PSBC"
					},
					{
						"label": "中国农业发展银行",
						"value": "NYFZYH"
					},
					{
						"label": "中国光大银行",
						"value": "CEB"
					},
					{
						"label": "中国进出口银行",
						"value": "EXIMBANK"
					},
					{
						"label": "中国民生银行",
						"value": "CMBC"
					},
					{
						"label": "交通银行",
						"value": "BOCO"
					},
					{
						"label": "广发银行",
						"value": "CGB"
					},
					{
						"label": "华夏银行",
						"value": "HXB"
					}
				],
				IsChecked: false,
				dataValues: {
					Id: 0,
					RealName: '', //联系人
					Name: '', //名称
					Mobile: '', //手机号
					ProvinceCode: '',
					Province: '',
					CityCode: '',
					City: '',
					DdistrictCode: '',
					Ddistrict: '',
					AddressInfo: '', //地址
					BankType: '',
					BankName: '',
					BankCardNo: '',
					IdCard: '', //身份证号码
					BusinessImg: '', //营业执照
					IdImg1: '', //身份证正面
					IdImg2: '', //身份证反面
					ContractImg: '', //商家入驻协议
				},
				uploadUrl: this.$target + '/UploadUploadFile',
				BusinessImgList: [],
				IdImg1List: [],
				IdImg2List: [],
				contractList: [],
				headers: {},
				shopdata: {
					isshop: false
				},
				//上传pdf
				studyFile: false,
				ResumefileName: {
					fileName: '',
					prefix: '',
					suffix: '',
				},
				next: false
			}
		},
		created() {
			console.log('yibaobanklist', this.yibaobanklist)
			if (localStorage.getItem("userToken")) {
				this.headers['Authorization'] = `Bearer ${getToken()}`
			}

		},
		mounted() {
			var that = this
			layui.use('form', () => {
				//获取表单元素
				var form = layui.form;
				//表单初始化 随后让Layui渲染一次
				$("#distpicker2").distpicker();
				form.render();
				//声明表单select选择事件
				form.on('select(selctOnchange)', function(data) {
					var e = data.elem;
					//获取选中的值
					console.log(data.value)
					that.dataValues.BankType = data.value
					//获取选中的文本
					var text = e[e.selectedIndex].text;
					that.dataValues.BankName = text
				});


				form.on('select(brickType1)', (data) => {
					// console.log('address_sheng', data.value)
					var e = data.elem;
					//获取自定义属性
					var datacode = e[e.selectedIndex].dataset.code;
					// console.log('data-code',datacode)
					that.dataValues.ProvinceCode = datacode
					that.dataValues.Province = data.value
					that.updata_address_select(data, "#address_sheng")
					form.render();
					//重新渲染一次
				});
				form.on('select(brickType2)', (data) => {
					// console.log('address_shi', data.value)
					var e = data.elem;
					//获取自定义属性
					var datacode = e[e.selectedIndex].dataset.code;
					// console.log('data-code',datacode)
					that.dataValues.CityCode = datacode
					that.dataValues.City = data.value
					that.updata_address_select(data, "#address_sheng")
					form.render();
					//重新渲染一次
				});
				form.on('select(brickType3)', (data) => {
					// console.log('address_qv', data.value)
					var e = data.elem;
					//获取自定义属性
					var datacode = e[e.selectedIndex].dataset.code;
					// console.log('data-code', datacode)
					that.dataValues.DdistrictCode = datacode
					that.dataValues.Ddistrict = data.value
					that.updata_address_select(data, "#address_qv")
					form.render();
					//重新渲染一次
				});
			});
			this.getshop()
		},
		methods: {
			async getshop() {
				var that = this
				const res = await that.postdata('/Shop/UserShopInfo', {})
				if (res.code == 200) {
					that.shopdata = res.data
					console.log('shopdata', that.shopdata)
					if (res.data.shop && res.data.shop.Id > 0) {
						that.dataValues = res.data.shop
						that.IdImg1List = [{
							name: '身份证正面.jpg',
							url: that.dataValues.IdImg1
						}]
						that.IdImg2List = [{
							name: '身份证反面.jpg',
							url: that.dataValues.IdImg2
						}]
						that.BusinessImgList = [{
							name: '营业执照.jpg',
							url: that.dataValues.BusinessImg
						}]

						that.ResumefileName.fileName = '商家入驻协议.pdf'

						layui.use(['form'], () => {
							var form = layui.form;
							// $("#distpicker2").distpicker();
							// form.render();

							console.log('shopdata22', that.dataValues)

							that.Editupdata_address_select(that.dataValues.BankType, '#bank_type');

							that.Editupdata_address_select(that.dataValues.Province, '#address_sheng');

							that.Editupdata_address_select(that.dataValues.City, '#address_shi');

							that.Editupdata_address_select(that.dataValues.Ddistrict, '#address_qv');

							form.render();
						})
					}
				}
			},
			async SubmitShopInfo() {
				if (this.dataValues.RealName == '' || !this.dataValues.RealName) {
					this.notifyError('联系人不能为空');
					return false
				}
				if (this.dataValues.Name == '' || !this.dataValues.Name) {
					this.notifyError('店铺名称不能为空');
					return false
				}
				if (this.dataValues.Mobile == '' || !this.dataValues.Mobile) {
					this.notifyError('手机号不能为空');
					return false
				}
				if (this.dataValues.Address == '' || !this.dataValues.Address) {
					this.notifyError('地址不能为空');
					return false
				}
				if (this.dataValues.IdCard == '' || !this.dataValues.IdCard) {
					this.notifyError('身份证号码不能为空');
					return false
				}
				if (this.dataValues.BankType == '' || !this.dataValues.BankType) {
					this.notifyError('请选择银行类型');
					return false
				}
				if (this.dataValues.BankCardNo == '' || !this.dataValues.BankCardNo) {
					this.notifyError('银行卡号不能为空');
					return false
				}
				if (this.dataValues.ProvinceCode == '' || !this.dataValues.ProvinceCode) {
					this.notifyError('请选择省');
					return false
				}
				if (this.dataValues.CityCode == '' || !this.dataValues.CityCode) {
					this.notifyError('请选择省');
					return false
				}
				if (this.dataValues.DdistrictCode == '' || !this.dataValues.DdistrictCode) {
					this.notifyError('请选择区');
					return false
				}
				
				
				
				if (!this.next) {
					this.next = true
					return
				}
				if (!this.$store.getters.getUser) {
					this.$store.dispatch("setShowLogin", true);
					return;
				}
				if (this.dataValues.BusinessImg == '') {
					this.notifyError('请上传营业执照');
					return false
				}
				if (this.dataValues.IdImg1 == '' || !this.dataValues.IdImg1) {
					this.notifyError('请上传身份证正面');
					return false
				}
				if (this.dataValues.IdImg2 == '' || !this.dataValues.IdImg2) {
					this.notifyError('请上传身份证反面');
					return false
				}
				if (this.dataValues.ContractImg == '' || !this.dataValues.ContractImg) {
					this.notifyError('请上传商家入驻协议');
					return false
				}

				this.IsChecked = true
				const res = await this.postdata('/Shop/SumbitShop', this.dataValues)
				if (res.code == 200) {
					this.notifySucceed("提交成功,等待后台审核");
					this.dataValues = {
					RealName: '', //联系人
					Name: '', //名称
					Mobile: '', //手机号
					ProvinceCode: '',
					Province: '',
					CityCode: '',
					City: '',
					DdistrictCode: '',
					Ddistrict: '',
					AddressInfo: '', //地址
					BankType: '',
					BankName: '',
					BankCardNo: '',
					IdCard: '', //身份证号码
					BusinessImg: '', //营业执照
					IdImg1: '', //身份证正面
					IdImg2: '', //身份证反面
					ContractImg: '', //商家入驻协议
					}
					this.$router.replace('/')
				}
				this.IsChecked = false
			},
			handleRemove(file, fileList, type) {
				//type  0.营业执照 1.身份证正面 2.身份证反面 3.商家入驻
				switch (type) {
					case 0:
						this.dataValues.BusinessImg = ''
						this.BusinessImgList = []
						break;
					case 1:
						this.dataValues.IdImg1 = ''
						this.IdImg1List = []
						break;
					case 2:
						this.dataValues.IdImg2 = ''
						this.IdImg2List = []
						break;
					case 3:
						this.dataValues.ContractImg = ''
						this.contractList = []
						break;
					default:
						break;
				}
			},
			handleSuccess(res, file, fileList, type) {
				//type  0.营业执照 1.身份证正面 2.身份证反面
				console.log(res, file, fileList, type)
				switch (type) {
					case 0:
						this.dataValues.BusinessImg = res.data
						break;
					case 1:
						this.dataValues.IdImg1 = res.data
						break;
					case 2:
						this.dataValues.IdImg2 = res.data
						break;
					case 3:
						this.dataValues.ContractImg = res.data
						break;
					default:
						break;
				}
				console.log(this.contractList)
			},
			withdrawFun() {
				var that = this
				layer.prompt({
					value: that.shopdata.amount,
					title: '请输入提现金额,并确认',
					formType: 0
				}, function(text, index) {
					layer.close(index);
					var money = text
					var data = {
						queryType: 0,
						queryName: money
					}
					that.postdata('/Shop/UserShopH5', data).then(res => {
						if (res.code == 200) {
							that.notifySucceed("提交提现申请成功,等待平台处理");
						}
					})

				});
			},

			async openwin(type) {
				//type  0-余额提现 1-绑定银行卡 2-解绑银行卡 3-设置密码 4-修改密码

				var data = {
					queryType: type
				}
				const res = await this.postdata('/Shop/UserShopH5', data)
				if (res.code == 200) {
					window.open(res.data, "_blank");
				}
			},
			beforeStudtUpload(file, type) { //可以获取上传的大小和类型
				const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1)
				const whiteList = ['pdf']
				console.log(file, type, fileSuffix)
				if (whiteList.indexOf(fileSuffix) === -1) {
					this.notifyError('上传文件只能是 pdf');
					return false
				}
			},

			// 获取文件名、文件名前缀、后缀
			imgPreview(file, type) {
				let array = file.name.split('.')
				let prefix = array[0]
				let suffix = array[1]
				this.ResumefileName.fileName = file.name
				this.ResumefileName.prefix = prefix
				this.ResumefileName.suffix = suffix
			},

			// 上传成功的回调，返回上传的路径
			StudtUploadSuccess(res) {
				var that = this
				this.contractList = []
				var newdata = []
				var data = []
				var img = this.getUrl(res.data)
				data.push(img)
				newdata = data
				console.log(newdata, img)
				this.contractList = newdata.map(item => {
					return {
						name: this.ResumefileName.fileName,
						url: item
					}
				});
				this.dataValues.ContractImg = this.contractList[0].url
				console.log(res, this.contractList)
			},
			updata_address_select(data, id) {
				var val = data.value;
				var sel = $(id);
				sel.val(data.value);
				sel.change();
			},
			Editupdata_address_select(data, id) {
				var val = data;
				var sel = $(id);
				sel.val(data);
				sel.change();
			},
		},


	}
</script>
<style scoped>
	.pdflist {
		overflow: hidden;
		z-index: 0;
		background-color: #fff;
		border: 1px solid #c0ccda;
		border-radius: 6px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		margin-top: 10px;
		padding: 21px 10px 21px 10px;
		height: 92px;
	}

	.pdflist span {
		margin-left: 6px;
	}


	/* 订单为空的时候显示的内容CSS */
	.userInfo .Personalbtn {
		/* margin-left: 18%; */
		/* margin-top: 16px; */
		margin: 16px auto;
	}

	.clearfix {
		clear: both;
	}

	.clearfix:after,
	.clearfix:before {
		content: " ";
		clear: both;
	}

	.box {
		display: flex;
		align-items: center;
		margin-bottom: 12px;
		justify-content: center;
	}

	.userInfo .text {
		width: 120px;
		font-size: 15px;
		text-align: right;
	}

	.userInfo .rt {}

	.userInfo .rt .input {
		border: 1px solid #bbb;
		font-size: 15px;
		line-height: 30px;
		padding: 0 6px;
		border-radius: 4px;
	}



	.box-bd {
		margin-top: 16px;
	}

	.uc-box .order-empty {
		margin: 0 auto;
	}

	.uc-box .order-empty .empty {
		height: 300px;
		padding: 0 0 130px 455px;
		margin: 65px 0 0;
		background: url(../../assets/imgs/cart-empty.png) no-repeat 32px 0;
		color: #b0b0b0;
		overflow: hidden;
	}

	.uc-box .order-empty .empty h2 {
		margin: 70px 0 15px;
		font-size: 36px;
	}

	.uc-box .order-empty .empty p {
		margin: 0 0 20px;
		font-size: 20px;
	}

	.uc-box {
		background: #fff
	}

	.uc-box .uc-content-box {
		margin: 0 48px;
		border: 1px solid #e6e6e6;
	}

	.uc-box .uc-content-box .box-hd .title {
		/* margin: 0;
		font-size: 24px;
		font-weight: 400;
		line-height: 68px;
		color: #757575 */
		background: #f3f3f3;
		height: 40px;
		font: bold 14px/40px '新宋体';
		padding: 0 0 0 20px;
	}

	.uc-box .uc-content-box .box-hd .more {
		border-bottom: 0;
	}

	.uc-box .uc-content-box .box-hd .filter-list {
		float: left;
		margin: 0;
		padding: 18px 0;
		list-style-type: none;
		font-size: 16px;
		line-height: 1.25
	}

	.uc-box .uc-content-box .box-hd .filter-list a {
		color: #757575;
		cursor: pointer
	}

	.uc-box .uc-content-box .box-hd .filter-list li {
		float: left;
		padding: 0 20px;
		border-left: 1px solid #e0e0e0;
		color: #757575
	}

	.uc-box .uc-content-box .box-hd .filter-list li.first {
		padding-left: 0;
		border-left: 0
	}

	.uc-box .uc-content-box .box-hd .filter-list li.active,
	.uc-box .uc-content-box .box-hd .filter-list li.active a,
	.uc-box .uc-content-box .box-hd .filter-list li.tab-active,
	.uc-box .uc-content-box .box-hd .filter-list li.tab-active a {
		color: #ff6700
	}

	.hide {
		display: none !important
	}

	.uc-order-item {
		position: relative
	}

	.btn {
		display: inline-block;
		width: 158px;
		height: 38px;
		padding: 0;
		margin: 0;
		border: 1px solid #b0b0b0;
		font-size: 14px;
		line-height: 38px;
		text-align: center;
		color: #b0b0b0;
		cursor: pointer;
		-webkit-transition: all .4s;
		transition: all .4s
	}

	.btn:hover {
		text-decoration: none;
		color: #b0b0b0
	}

	.btn:focus {
		outline: 0
	}

	.btn:active {
		-webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, .18);
		box-shadow: inset 0 2px 4px rgba(0, 0, 0, .18)
	}

	.btn[disabled] {
		border-style: dashed !important;
		border-color: #e0e0e0;
		background-color: #fff !important
	}

	.btn-disabled,
	.btn[disabled] {
		color: #b0b0b0 !important;
		cursor: default !important
	}

	.btn-disabled {
		background: #e0e0e0 !important;
		border-color: #e0e0e0 !important
	}

	.btn-small {
		width: 118px;
		height: 28px;
		font-size: 12px;
		line-height: 28px
	}

	.btn-large {
		width: 178px;
		height: 48px;
		line-height: 48px
	}

	.btn-biglarge {
		width: 298px;
		height: 52px;
		line-height: 52px;
		font-size: 16px
	}

	.btn-block {
		display: block;
		width: 100%;
		padding-left: 0;
		padding-right: 0
	}

	.btn-primary {
		background: #cc0000;
		border-color: #cc0000;
		color: #fff;
	}

	button.btn,
	input.btn {
		width: 160px;
		height: 40px
	}

	button.btn-small,
	input.btn-small {
		width: 120px;
		height: 30px
	}

	.ordernum {
		color: #757575;
	}

	/* 订单为空的时候显示的内容CSS END */
	.steps {
		width: 975px;
		height: 120px;
		/* margin:40px 0 0 15px; */
		/* padding-left: 34px; */
	}

	.steps li,
	.steps .cur,
	.steps .cur .going,
	.steps li.last {
		background-image: url(../../images/steps_images.png);
		background-repeat: no-repeat;
	}

	.steps li {
		background-position: 0 -8px;
		display: inline;
		float: left;
		height: 32px;
		line-height: 32px;
		margin-right: 190px;
		position: relative;
		text-align: center;
		text-indent: -1px;
		width: 32px;
	}

	.steps li span {
		background: none no-repeat scroll 0 0 #cccccc;
		display: block;
		width: 190px;
		height: 8px;
		left: 32px;
		overflow: hidden;
		position: absolute;
		top: 12px;
	}

	.steps .going {
		background: none no-repeat scroll 0 0 #cc0000;
	}

	.steps .f {
		display: block;
		left: -60px;
		position: absolute;
		text-align: center;
		top: 45px;
		width: 152px;
		font: bold 14px/14px '新宋体';
		color: #a5a5a5;
	}

	.steps b {
		color: #fff;
		font-family: "Arial";
		font-size: 20px;
	}

	.steps li.last {
		margin: 0;
		background-position: -32px -8px;
	}

	.steps li.last b {
		display: none;
	}

	.steps li.cur {
		background-position: 0 -10px;
	}

	.steps li.prev {
		background-position: 0 -10px;
	}

	.steps li.ok {
		background-position: -64px -8px;
	}

	.steps li.ok b {
		display: block;
	}

	.steps li.prev .going {
		background-color: #c00;
		background-image: none;
	}

	.steps li.cur .going {
		background-position: 0 0;
	}

	.steps li.prev .f,
	.steps lis.cur .f {
		color: #c00;
	}

	.audit-steps {
		width: 807px;
		height: 44px;
		margin: 40px 0 0 35px;
	}

	.steps li,
	.steps .cur,
	.steps .cur .going,
	.steps li.last {
		background-image: url(../../images/steps_images.png);
		background-repeat: no-repeat;
	}

	.audit-steps li {
		background-color: #ebebeb;
		display: inline;
		float: right;
		height: 44px;
		line-height: 44px;
		margin-right: 1px;
		position: relative;
		text-align: center;
		text-indent: -1px;
		width: 201px;
	}

	.audit-steps li span {
		background-position: 0 0;
		display: block;
		width: 26px;
		height: 26px;
		right: -13px;
		overflow: hidden;
		position: absolute;
		top: 9px;
		z-index: 9999;
	}

	.audit-steps li.last {
		margin: 0;
	}

	.audit-steps b {
		color: #a5a5a5;
		font-family: "Arial";
		font-size: 14px;
		font-weight: 400;
	}

	.audit-steps li.prev {
		background-color: #d63333;
	}

	.audit-steps li.prev b {
		color: #fff;
	}

	.audit-steps li.cur b {
		color: #cc0000;
	}

	.audit-steps li span,
	.audit-steps li.prev .going {
		/* background-image:url(../../images/audit-steps.png); */
		/* _background-image:url(../images/audit-steps.gif); */
		background-repeat: no-repeat;
	}

	.audit-steps li.prev .going {
		background-position: -26px 0;
	}
</style>
